;(function($) {

/**
 * The filters applied to dete
 */
var filters = []

/**
 * Called when the preloading of all images must start.
 * @function onStart
 * @since 1.0.0
 */
var onStart = function() {

	$(document.body).attr('data-loaded', 0)

	var loaded = 0
	var length = 0
	var object = {value:0}

	if (document.readyState !== 'complete') {
		length = 1
	}

	/**
	 * Updates the progress value.
	 * @function update
	 * @since 1.0.0
	 */
	var update = function() {

		var value = length ? (++loaded / length) * 100 : 100

		var complete = function() {
			$.publish('loading/complete')
		}

		var progress = function(value) {
			$.publish('loading/progress', value)
		}

		$(object).stop().animate({value:value}, { duration:500, complete:complete, step:progress })

		$(document.body).attr('data-loaded', value)
	}

	/**
	 * Appends an image to be preloaded.
	 * @function append
	 * @since 1.0.0
	 */
	var append = function(url) {

		var image = new Image()

		image.src = url

		if (image.complete === false) {
			image.addEventListener('load', update)
			image.addEventListener('error', update)
			length++
		}
	}

	/**
	 * Process every element in order to find whether they are attach to
	 * a resource that needs to be preloaded.
	 */
	$('*').each(function(i, element) {
		$.each(filters, function(i, filter) {
			var url = filter.call(null, element)
			if (url) {
				append(url)
			}
		})
	})

	if (length === 0) {
		update()
	}

	$(window).on('load', update)
}

/**
 * Called when the preloading of all images is requested to stop.
 * @function onStop
 * @since 1.0.0
 */
var onStop = function() {

}

/**
 * Process Background Images
 * @since 1.0.0
 */
filters.push(function(element) {

	var image = $(element).css('background-image').match(/\((.*?)\)/)
	if (image) {
		var url = image[1].replace(/('|")/g,'')
		if (url) {
			return url
		}
	}

	return null
})

/**
 * Process HTML Image element
 * @since 1.0.0
 */
filters.push(function(element) {

	if (element.tagName === 'IMG') {
		var src = element.getAttribute('src')
		if (src) {
			return src
		}
	}

	return null
})

/**
 * Process SVG Image element
 * @since 1.0.0
 */
filters.push(function(element) {

	if (element.tagName === 'IMAGE') {
		var href = element.getAttributeNS('http://www.w3.org/1999/xlink', 'href')
		if (href) {
			return href
		}
	}

	return null
})

$.subscribe('loading/start', onStart)
$.subscribe('loading/stop', onStop)

$(document).ready(function() {
	$.publish('loading/start')
})

})(jQuery);