(function($) {
"use strict"

/**
 * Creates animations on an element based on the scroll.
 * @author Jean-Philippe Dery (jeanphilippe.dery@jblp.ca)
 * @version 1.0.0
 */

var csstransform2d = true
var csstransform3d = true

$.attach('[data-scroll-animation]', function(i, element) {

    element = $(element)

    //------------------------------------------------------------------------------
    // Properties
    //------------------------------------------------------------------------------

    /**
     * The child element to receive the styles.
     * @property target
     * @since 1.0.0
     */
    var target = element.attr('data-scroll-animation-target')
    if (target == null) {
        target = element
    } else {
        target = element.find(target)
    }

    /**
     * The animated property.
     * @property propertyName
     * @since 1.0.0
     */
    var propertyName = ['background-position']

    /**
     * The animated property format.
     * @property propertyFormat
     * @since 1.0.0
     */
    var propertyFormat = ['0px %v%']

    /**
     * The animated property minimal value.
     * @property propertyMinValue
     * @since 1.0.0
     */
    var propertyMinValue = [0]

    /**
     * The animated property maximal value.
     * @property propertyMaxValue
     * @since 1.0.0
     */
    var propertyMaxValue = [100]

    /**
     * The element enter offset.
     * @property offsetTop
     * @since 1.0.1
     */
    var offsetTop = 0

    /**
     * The element leave offset.
     * @property offsetBot
     * @since 1.0.1
     */
    var offsetBot = 0

    /**
     * Wether the element is visible.
     * @property visible
     * @since 1.0.0
     */
    var visible = false

    /**
	 * Returns the visible area of the element.
	 * @function getVisibility
	 * @since 1.0.1
	 */
	var getVisibility = function() {

		var offsetT = offsetTop
		var offsetB = offsetBot
		var scrollX = $(window).scrollLeft()
		var scrollY = $(window).scrollTop()
		var windowSizeX = $(window).width()
		var windowSizeY = $(window).height()

		offsetT -= scrollY
		offsetB -= scrollY

		return offsetT < 0 ? 1 : 1 - (offsetT / windowSizeY)
	}

	/**
	 * Update the animation progress.
	 * @function updateProgress
	 * @since 1.0.0
	 */
	var updateProgress = function() {

		var once = false

		var progress = getVisibility()

		if (progress > 0) {
			if (visible == false) {
				visible = true
				once = true
			}
		} else {
			if (visible) {
				visible = false
				once = true
			}
		}

		if (progress || once) {

            if (progress > 1) progress = 1
			if (progress < 0) progress = 0

            $.each(propertyName, function(i, property) {

                var name = propertyName[i]
        		var fmrt = propertyFormat[i]
        		var min = propertyMinValue[i]
        		var max = propertyMaxValue[i]

                target.css(name, fmrt.replace('%v', interpolate(progress, min, max)))

            })
		}
	}

	/**
	 * Update animation element measures.
	 * @function updateOffsets
	 * @since 1.0.0
	 */
	var updateOffsets = function() {
        var offset = element.offset()
		offsetTop = offset.top
		offsetBot = offset.top + element.outerHeight()
	}

	//--------------------------------------------------------------------------
	// Events
	//--------------------------------------------------------------------------

	/**
	 * updateOffsets and update the animation.
	 * @function onWindowResize
	 * @since 1.0.0
	 */
	var onWindowResize = function() {
		updateOffsets()
		updateProgress()
	}

	/**
	 * Update the animation.
	 * @function onWindowScroll
	 * @since 1.0.0
	 */
	var onWindowScroll = function() {
		updateProgress()
	}

    /**
     * Update
     * @function onWindowLoad
     * @since 1.0.0
     */
    var onWindowLoad = function() {
        updateOffsets()
		updateProgress()
    }

    /**
     * Interpolate the value based on a progress value.
     * @function interpolate
     * @since 1.0.0
     */
    var interpolate = function(progress, min, max) {
    	return progress * (max - min) + min
    }

    //--------------------------------------------------------------------------
	// Initialization
	//--------------------------------------------------------------------------

    propertyName = element.attr('data-scroll-animation').split('|') || ['background-position']
    propertyFormat = element.attr('data-scroll-animation-format').split('|') || ['0px %v%']
    propertyMinValue = element.attr('data-scroll-animation-min').split('|').map(parseFloat) || [0]
    propertyMaxValue = element.attr('data-scroll-animation-max').split('|').map(parseFloat) || [0]

    $(window).on('resize', onWindowResize)
    $(window).on('scroll', onWindowScroll)
    $(window).on('load', onWindowLoad)
    updateOffsets()
    updateProgress()

})

})(jQuery);
