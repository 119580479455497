(function($) {
"use strict"

$.attach('[data-watch]', function(i, element) {

	element = $(element)

	//--------------------------------------------------------------------------
	// Properties
	//--------------------------------------------------------------------------

	/**
	 * The element current top offset.
	 * @var offsetTop
	 * @since 1.0.0
	 */
	var offsetTop = 0

	/**
	 * The element current bottom offset.
	 * @var offsetBot
	 * @since 1.0.0
	 */
	var offsetBot = 0

	/**
	 * The type of events to watch (enter, leave, both)
	 * @var watch
	 * @since 1.0.0
	 */
	var watch = element.attr('data-watch') || 'both'

	/**
	 * The percentage of getVisibility required to enter.
	 * @var enterAt
	 * @since 1.0.0
	 */
	var enterAt = element.attr('data-enter-at') || 0.30

	/**
	 * The percentage of getVisibility required to leave.
	 * @var enterAt
	 * @since 1.0.0
	 */
	var leaveAt = element.attr('data-leave-at') || 0

	/**
	 * Whether the element is currently visible.
	 * @var since
	 * @since 1.0.0
	 */
	var visible = false

	/**
	 * Whether the page has been loaded.
	 * @var loaded
	 * @since 1.0.1
	 */
	var loaded = false

	//--------------------------------------------------------------------------
	// Functions
	//--------------------------------------------------------------------------

	/**
	 * Returns the visible area of the element.
	 * @function getVisibility
	 * @since 1.0.0
	 */
	var getVisibility = function() {

		var offsetT = offsetTop
		var offsetB = offsetBot
		var scrollX = $(window).scrollLeft()
		var scrollY = $(window).scrollTop()
		var windowSizeX = $(window).width()
		var windowSizeY = $(window).height()

		offsetT -= scrollY
		offsetB -= scrollY

		// hidden
		if (offsetB < 0 ||
			offsetT > windowSizeY)
			return 0

		// fills or contains
		if (offsetT <= 0 && offsetB >= windowSizeY ||
			offsetT >= 0 && offsetB <= windowSizeY)
			return 1

		// contents larger than screen
		if (offsetB - offsetT > windowSizeY) {

			var visibility = 1 - offsetT / windowSizeY
			if (visibility > 1) {
				visibility = 1
			}

			return visibility
		}

		return offsetT > 0 ? (windowSizeY - offsetT) / (offsetB - offsetT) : 1 - Math.abs(offsetT) / (offsetB - offsetT)
	}

	/**
	 * Updates the css classes used to indicate the visibility state.
	 * @function updateStatus
	 * @since 1.0.0
	 */
	var updateStatus = function() {

		var progress = getVisibility()

		element.attr('data-visibility', Math.round(progress * 10) * 10)

		if (progress > 1) {
			progress = 1
		} else if (progress < 0) {
			progress = 0
		}

		if (progress > 0) {
			if (visible === false && (watch === 'enter' || watch === 'both' || loaded == false) && progress >= enterAt) {
				visible = true
				element.addClass('visible-on-screen')
				element.trigger('enterscreen')
			}
		} else {
			if (visible === true && (watch === 'leave' || watch === 'both' || loaded == false) && progress <= leaveAt) {
				visible = false
				element.removeClass('visible-on-screen')
				element.trigger('leavescreen')
			}
		}
	}

	/**
	 * updateOffsetses the element offset.
	 * @function updateOffsets
	 * @since 1.0.0
	 */
	var updateOffsets = function() {
		var offset = element.offset()
		offsetTop = offset.top
		offsetBot = offset.top + element.outerHeight()
		updateStatus()
	}

	/**
	 * Update the offsets when the window loads.
	 * @function onWindowLoad
	 * @since 1.0.0
	 */
	var onWindowLoad = function() {
		updateOffsets()
		loaded = true
	}

	/**
	 * Update the offsets when the window resizes.
	 * @function onWindowResize
	 * @since 1.0.0
	 */
	var onWindowResize = function() {
		updateOffsets()
	}

	/**
	 * Update the offsets when the window scrolls.
	 * @function onWindowScroll
	 * @since 1.0.0
	 */
	var onWindowScroll = function() {
		updateStatus()
	}

	//--------------------------------------------------------------------------
	// Initialization
	//--------------------------------------------------------------------------

	$(window).on('load', onWindowLoad)
	$(window).on('resize', onWindowResize)
	$(window).on('scroll', onWindowScroll)

	if (document.readyState === 'complete') {
		loaded = true
	}

	updateOffsets()
})

})(jQuery);
