(function($) {
"use strict"

/**
 * Stop scrolling animtion flicks across browsers.
 * @author Jean-Philippe Dery (jeanphilippe.dery@jblp.ca)
 * @version 1.0.0
 */

var touch = Modernizr.touch
var csstransform2d = Modernizr.csstransforms
var csstransform3d = Modernizr.csstransforms3d

try {
    document.createEvent('TouchEvent')
} catch (e) {
    touch = false
}

$.attach('[data-scroll-animation-container]', function(i, element) {

	//--------------------------------------------------------------------------
	// Properties
	//--------------------------------------------------------------------------

    /**
	 * The scroll animation container element
	 * @property content
	 * @since 1.0.0
	 */
    var element = $(element)

	/**
	 * The container content element.
	 * @property content
	 * @since 1.0.0
	 */
	var content = null

	/**
	 * The container width.
	 * @property contentSizeX
	 * @since 1.0.0
	 */
	var contentSizeX = 0

	/**
	 * The container height.
	 * @property contentSizeY
	 * @since 1.0.0
	 */
	var contentSizeY = 0

	/**
	 * The current scroll x position.
	 * @property contentScrollX
	 * @since 1.0.0
	 */
	var contentScrollX = 0

	/**
	 * The current scroll y position.
	 * @property contentScrollY
	 * @since 1.0.0
	 */
	var contentScrollY = 0

	/**
	 * Wether the x direction is scrollable.
	 * @property xAxis
	 * @since 1.0.0
	 */
	var xAxis = (element.attr('data-axis') || 'y').indexOf('x') > -1

	/**
	 * Wether the y direction is scrollable.
	 * @property yAxis
	 * @since 1.0.0
	 */
	var yAxis = (element.attr('data-axis') || 'y').indexOf('y') > -1

    /**
	 * Which transforms are used
	 * @property yAxis
	 * @since 1.0.0
	 */
	var transform = element.attr('data-transform') || '2d'

    /**
	 * The interval in which the size is updated.
	 * @property updateSizeInterval
	 * @since 1.0.0
	 */
    var updateSizeInterval = null

	//--------------------------------------------------------------------------
	// Methods
	//--------------------------------------------------------------------------

	/**
	 * Update the size properties.
	 * @method resize
	 * @since 1.0.0
	 */
	var updateSize = function() {

        var nextSizeX = content.scrollWidth()
        var nextSizeY = content.scrollHeight()

        if (contentSizeX !== nextSizeX && xAxis) {
            contentSizeX = nextSizeX
            element.css('width',  nextSizeX)
        }

        if (contentSizeY !== nextSizeY && yAxis) {
            contentSizeY = nextSizeY
            element.css('height',  nextSizeY)
        }
    }

	/**
	 * Update the visual offset.
	 * @method scroll
	 * @since 1.0.0
	 */
	var updateScroll = function() {

		contentScrollX = $(window).scrollLeft()
		contentScrollY = $(window).scrollTop()

		if (touch === false) {

			var x = -contentScrollX
			var y = -contentScrollY

			if (xAxis === false) x = 0
			if (yAxis === false) y = 0

			if (transform === '3d' && csstransform3d) {
				content.css('-webkit-transform', 'translate3d(' + x + 'px, ' + y + 'px, 0)')
				content.css('-moz-transform',    'translate3d(' + x + 'px, ' + y + 'px, 0)')
				content.css('-ms-transform',     'translate3d(' + x + 'px, ' + y + 'px, 0)')
				content.css('transform',         'translate3d(' + x + 'px, ' + y + 'px, 0)')
				return
			}

			if (transform === '2d' && csstransform2d) {
				content.css('-webkit-transform', 'translateX(' + x + 'px) translateY(' + y + 'px)')
				content.css('-moz-transform',    'translateX(' + x + 'px) translateY(' + y + 'px)')
				content.css('-ms-transform',     'translateX(' + x + 'px) translateY(' + y + 'px)')
				content.css('transform',         'translateX(' + x + 'px) translateY(' + y + 'px)')
				return
			}

			content.css('top',  y)
			content.css('left', x)
		}
	}

	//--------------------------------------------------------------------------
	// Events
	//--------------------------------------------------------------------------

	/**
	 * Update the container size when the window resizes.
	 * @method onWindowResize
	 * @since 1.0.0
	 */
	var onWindowResize = function() {
		updateSize()
	}

	/**
	 * Update the scroll position when the window scrolls.
	 * @method onWindowScroll
	 * @since 1.0.0
	 */
	var onWindowScroll = function() {
		updateScroll()
	}

	/**
	 * Attach the window events once the document is ready.
	 * @method onWindowResize
	 * @since 1.0.0
	 */
	var onDocumentReady = function() {

        updateSize()
		updateScroll()

        $(window).on('scroll', onWindowScroll)
		$(window).on('resize', onWindowResize)

        updateSizeInterval = setInterval(updateSize, 500)
	}

    //--------------------------------------------------------------------------
    // Initialization
    //--------------------------------------------------------------------------

	var inside = $(element).children()

	content = $('<div class="scroll-animation-container-content"></div>')
    content.append(inside)
	element.append(content)

	if (touch === false) {
		element.css('position', 'relative')
		content.css('position', 'fixed')
		content.css('height', '100%')
		content.css('width', '100%')
	}

	$(document).on('ready', onDocumentReady)
})

/**
 * Return the scroll width in a jQuery style plugin.
 * @function scrollWidth
 * @since 1.0.0
 */
$.fn.scrollWidth = function() {
	return this.get(0).scrollWidth
}

/**
 * Return the scroll height in a jQuery style plugin.
 * @function scrollHeight
 * @since 1.0.0
 */
$.fn.scrollHeight = function() {
	return this.get(0).scrollHeight
}

})(jQuery);
