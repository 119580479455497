(function($) {
"use strict"

$.attach('[data-content]', function(i, element) {

	element = $(element)

	//------------------------------------------------------------------
	// Variables
	//------------------------------------------------------------------

	/**
	 * Whether the content is currently loading.
	 * @var loading
	 * @since 1.0.0
	 */
	var loading = false

	/**
	 * The fetched content to present during loading.
	 * @var contentToPreset
	 * @since 1.0.0
	 */
	var contentToPresent = null

	/**
	 * The current content to dismiss during loading.
	 * @var contentToPreset
	 * @since 1.0.0
	 */
	var contentToDismiss = null

	/**
	 * The duration of the present animation.
	 * @var presentDuration
	 * @since 1.0.0
	 */
	var presentDuration = element.attr('data-present-duration') || 250

	/**
	 * The duration of the dismiss animation.
	 * @var presentDuration
	 * @since 1.0.0
	 */
	var dismissDuration = element.attr('data-dismiss-duration') || 250

	/**
	 * The next url to load.
	 * @var next
	 * @since 1.0.0
	 */
	var next = null

	//-------------------------------------------------------------------------
	// Functions
	//-------------------------------------------------------------------------

	/**
	 * Replaces the specified content from the fetched content.
	 * @function load
	 * @since 1.0.0
	 */
	var load = function(url) {

		if (loading) {
			next = url
			return
		}

		loading = true

		var contentToPresentClass = null
		var contentToPresentTitle = null
		var contentToPresentLoaded = false
		var contentToDismissHidden = false

		/**
		 * Fetches the content of an url.
		 * @function fetch
		 * @since 1.0.0
		 */
		var fetch = function(url, callback) {

			var cache = caches[url]
			if (cache) {
				callback(cache)
				return
			}

			$.get(url).done(function(html) {
				caches[url] = html
				callback(html)
			}.bind(this)).fail(function() {
				location.reload()
			})

		}.bind(this)

		/**
		 * Parses and present the content from the url.
		 * @function present
		 * @since 1.0.0
		 */
		var present = function(url) {

			if (contentToPresentLoaded === false ||
				contentToDismissHidden === false)
				return

			window.scrollTo(0, 0)

			var html = caches[url]
			html.replace('<html>', '')
			html.replace('</html>', '')

			var dom = document.createElement('html')
			dom.innerHTML = html
			dom = $(dom)

			updateLinks(dom)

			$.detach(contentToDismiss.children())

			contentToPresent = dom.find('[data-content]')
			contentToPresentClass = dom.find('body').attr('class')
			contentToPresentTitle = dom.find('head').find('title').text()
			contentToDismiss.replaceWith(contentToPresent)
			contentToPresent.css('opacity', 0)

			element = contentToPresent

			$(document.body).attr('class', contentToPresentClass)
			$(document.body).addClass('page-content-booted')
			$(document.body).addClass('page-content-loading')

			var onLoadingDone = function() {

				$.unsubscribe('loading/complete', onLoadingDone)

				$.attach.refresh(element.children())

				setTimeout(function() {

					contentToPresent.css('opacity', '')

					$(document.body).addClass('page-content-loaded')
					$(document.body).removeClass('page-content-leaving')
					$(document.body).removeClass('page-content-loading')

					setTimeout(onPresentAnimationEnd, presentDuration)

				}, 250)

				$.publish('content/present')
			}

			$.subscribe('loading/complete', onLoadingDone)

			$.publish('loading/start')
		}

		/**
		 * Called when the timer for the dismiss animation is called.
		 * @function onDismissAnimationEnd
		 * @since 1.0.0
		 */
		var onDismissAnimationEnd = function(e) {
			if (contentToDismissHidden === false) {
				contentToDismissHidden = true
				present(url)
				$.publish('content/dismiss')
			}
		}

		/**
		 * Called when the timer for the present animation is called.
		 * @function onPresentAnimationEnd
		 * @since 1.0.0
		 */
		var onPresentAnimationEnd = function(e) {

			$('title').text(contentToPresentTitle)

			loading = false
			contentToPresent = null
			contentToDismiss = null

			$(document.body).addClass('page-content-ready')

			if (next) {
				load(next)
				next = null
			}
		}

		fetch(url, function(html) {
			if (contentToPresentLoaded === false) {
				contentToPresentLoaded = true
				present(url)
			}
		})

		contentToDismiss = element

		setTimeout(onDismissAnimationEnd, dismissDuration)

		$(document.body).removeClass('page-content-loaded')
		$(document.body).removeClass('page-content-ready')

		$(document.body).addClass('page-content-loading')
		$(document.body).addClass('page-content-leaving')
		$(document.body).addClass('page-content-requesting')
	}

	/**
	 * @function updateLinks
	 * @since 1.1.0
	 */
	var updateLinks = function(dom) {

		$('.current-menu-item').removeClass('current-menu-item')
		$('.current-page-parent').removeClass('current-page-parent')
		$('.current-page-ancestor').removeClass('current-page-ancestor')

		dom.find('.current-menu-item').each(function(i, item) {

			var menu = $(item).attr('id')
			if (menu == null) {
				return
			}

			$('#' + menu).addClass('current-menu-item')

		})

		dom.find('.current-page-parent').each(function(i, item) {

			var menu = $(item).attr('id')
			if (menu == null) {
				return
			}

			$('#' + menu).addClass('current-page-parent')

		})

		dom.find('.current-page-ancestor').each(function(i, item) {

			var menu = $(item).attr('id')
			if (menu == null) {
				return
			}

			$('#' + menu).addClass('current-page-ancestor')

		})
	}

	//--------------------------------------------------------------------------
	// Pub/Sub
	//--------------------------------------------------------------------------

	/**
	 * Loads content from a specific URL into the content area.
	 * @subscribe content/load
	 * @since 1.0.0
	 */
	$.subscribe('content/load', load)

	/**
	 * Adds some CSS classes when the page load is complete.
	 * @subscribe loading/complete
	 * @since 1.0.0
	 */
	$.subscribe('loading/complete', function(progress) {

		setTimeout(function() {
			$(document.body).addClass('page-content-booted')
			$(document.body).addClass('page-content-loaded')
		}, 750)

		setTimeout(function() {
			$.publish('loading/progress', 0)
		}, 2500)

		setTimeout(function() {
			$(document.body).addClass('page-content-ready')
		}, 1250)

	})

})

/**
 * Cached elements for future reference.
 * @var caches
 * @since 1.0.0
 */
var caches = {}

})(jQuery);
